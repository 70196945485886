import ReactDOM from 'react-dom/client';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

// If you want to enable client cache, register instead.
// Якщо ви хочете ввімкнути кеш клієнта, зареєструйтеся.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function to log results (for example: reportWebVitals(console.log)) or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Якщо ви хочете почати вимірювати продуктивність у своїй програмі, передайте функцію для реєстрації результатів (наприклад: reportWebVitals(console.log)) або надішліть до кінцевої точки аналітики. Докладніше: https://bit.ly/
reportWebVitals();
