import { useContext } from 'react';
import { AuthContext } from '../hoc/AuthProvider';

export function useAuth() {
  return useContext(AuthContext);
}

// Встворимо власний хук.
// Визов цього хука, дасть нам доступ до:
// const value = (user, signin, signout)
// з файлу AuthProvader.jsx

// будемо використовувати його в Login.jsx та RequireAuth.jsx
