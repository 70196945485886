const langsets = [...Array(0)].map((_, index) => ({
  id: index,
  name: `name_${index}`,
  source: `name_${index} ${index}_ua ${index}_pl ${index}_en ${index}_ru`,
  ua: `${index}_ua`,
  pl: `${index}_pl`,
  en: `${index}_en`,
  ru: `${index}_ru`,
}));

// source для функуції applySortFilter - потрібно об'єднати строки для кращого пошуку
// Отримуємо дані з БД, передрати його і додати властивість source та передати в хук

export default langsets;
