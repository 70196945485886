import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
// Використовуємо локацію та редирект

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { user } = useAuth();

  //   Якщо немає авторизації
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
    // /login - куди переадресувати
    // from: location - звідки ми прийшли
  }

  //   Якщо все ок, то перейти на сторінку children
  return children;
};

// https://www.npmjs.com/package/prop-types
RequireAuth.propTypes = {
  children: PropTypes.node,
};

export { RequireAuth };
