const settings = {
  name: 'Назва',
  year: '2024',
  titleUa: '',
  descriptionUa: '',
  keywordsUa: '',
  titlePl: '',
  descriptionPl: '',
  keywordsPl: '',
  titleEn: '',
  descriptionEn: '',
  keywordsEn: '',
  titleRu: '',
  descriptionRu: '',
  keywordsRu: '',
  viewUa: false,
  viewPl: false,
  viewEn: false,
  viewRu: false,
  logo: null,
  logoLight: null,
};

export default settings;
