import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

// https://legacy.reactjs.org/docs/typechecking-with-proptypes.html
AlertDialog.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onContinue: PropTypes.func,
  agree: PropTypes.string,
  disagree: PropTypes.string,
};

export default function AlertDialog({ header, text, show, onHide, onContinue, agree, disagree }) {
  //   const [open, setOpen] = useState(show);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onContinue} variant="outlined">
            {agree || 'Продовжити'}
          </Button>
          <Button onClick={onHide} autoFocus variant="contained">
            {disagree || 'Відмінити'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
