import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Link,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  Paper,
  TablePagination,
  MenuItem,
  Popover,
} from '@mui/material';
import Iconify from '../components/iconify';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';
import LANGSETSLIST from '../_mock/langsets';
import { URL_GET_LANGSETS, URL_GET_LANGSETS_DELETE } from '../config';
import { useAuth } from '../hooks/useAuth';
import AlertDialog from '../components/dialog/AlertDialog';
import ProgressLinear from '../components/progress-linear/ProgressLinear';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'name', label: 'Tранслітерація', alignRight: false },
  { id: 'ua', label: 'UA', alignRight: false },
  { id: 'pl', label: 'PL', alignRight: false },
  { id: 'en', label: 'EN', alignRight: false },
  { id: 'ru', label: 'RU', alignRight: false },
  { id: '' },
];

export default function LangSets() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [langsetList, setLangsetList] = useState(LANGSETSLIST);
  const [open, setOpen] = useState(null);
  const [openId, setOpenId] = useState(null);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [uploadData, setUploadData] = useState(null);
  // AretDialog
  const [alertDialog, setAlertDialog] = useState(false);
  const [headerDialog, setHeaderDialog] = useState('');
  const [textDialog, setTextDialog] = useState('');

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setOpenId(id);
  };

  const handleDelete = (id, showDialog) => {
    setAlertDialog(showDialog);
    setHeaderDialog(`Видалення запису! [#${id}]`);
    setTextDialog(
      `Видаливши цей запис, ви можете порушити роботу деякого функціоналу! Ви впевнені, що хочете видалити цей запис?`
    );
    setOpen(null);
  };

  const deleteLangSet = (id) => {
    const data = {
      id: user.dataUser.id,
      idRow: id,
    };

    fetch(URL_GET_LANGSETS_DELETE, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.ok === 1 && json.status === 200) {
          setUploadData(data);
        }
      })
      .catch((error) => {
        // Помилка
        console.error(error);
      });

    setAlertDialog(false);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = langsetList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_el) => _el.source.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - langsetList.length) : 0;

  const filteredUsers = applySortFilter(langsetList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    setLoading(true);

    fetch(URL_GET_LANGSETS, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.ok === 1 && json.status === 200 && json.langsets) {
          // console.log(json);
          const { langsets } = json;
          // Показати перебраний результат
          setLangsetList(
            langsets.map((obj) => ({ ...obj, source: `${obj.name} ${obj.ua} ${obj.pl} ${obj.en} ${obj.ru}` }))
          );
        } else {
          // Показати пустий результат
          setLangsetList([]);
        }

        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData]);

  return (
    <>
      <Helmet>
        <title> Мовні набори </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Мовні набори
          </Typography>
          <Link to="add" component={RouterLink} sx={{ display: 'contents' }}>
            <Button variant="contained" href="" startIcon={<Iconify icon="eva:plus-fill" />}>
              Додати
            </Button>
          </Link>
        </Stack>

        <Card>
          {!loading || <ProgressLinear />}
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={langsetList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, ua, pl, en, ru } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell align="left">{id}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{ua}</TableCell>
                        <TableCell align="left">{pl}</TableCell>
                        <TableCell align="left">{en}</TableCell>
                        <TableCell align="left">{ru}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Не знайдено
                          </Typography>

                          <Typography variant="body2">
                            Не знайдено результатів для &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Спробуйте перевірити друкарські помилки або використати повні слова.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={langsetList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Link to={`edit/${openId}`} component={RouterLink} sx={{ display: 'contents' }}>
          <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Редагувати
          </MenuItem>
        </Link>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDelete(openId, true)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Видалити
        </MenuItem>
      </Popover>

      <AlertDialog
        header={headerDialog}
        text={textDialog}
        show={alertDialog}
        onHide={() => setAlertDialog(false)}
        onContinue={() => deleteLangSet(openId)}
        agree={`Видалити`}
        disagree={'Закрити'}
      />
    </>
  );
}
