import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Box,
  FormControlLabel,
  CircularProgress,
  Alert,
  Collapse,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../hooks/useAuth';
import { URL_LOGIN } from '../../../config';
import account from '../../../_mock/account';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signin } = useAuth();

  // const fromPage = location.state?.from?.pathname || '/login';
  const fromPage = location.state?.from?.pathname || '/';

  // console.log(fromPage);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState('Помилка входу!');

  const handleClick = (event) => {
    event.preventDefault();

    setIsLoading(false);

    const form = event.target;
    const user = form.email.value;
    const pass = form.password.value;

    if (user === '') {
      setTimeout(() => {
        setErrorText('Вкажіть електронну адресу!');
        setIsLoading(true);
        setOpen(true);
      }, 100);
      return;
    }

    if (pass === '') {
      setTimeout(() => {
        setErrorText('Вкажіть пароль!');
        setIsLoading(true);
        setOpen(true);
      }, 100);
      return;
    }

    const dataNameAndPass = {
      email: user,
      password: pass,
    };

    // Логін
    fetch(URL_LOGIN, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataNameAndPass),
    })
      .then((response) => response.json())
      .then((json) => {
        // В разі успіху отримаємо success, message та JWT token
        if (json?.success === 1) {
          // console.log('LoginForm json: ', json);
          signin({ ...json, dataUser: account }, () => navigate(fromPage, { replace: true }));
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setTimeout(() => {
          setIsLoading(true);
          setErrorText('Помилка входу!');
          setOpen(true);
        }, 1000);
      });
  };

  return (
    <>
      <Box component="form" onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField name="email" label="Електронна адреса" />

          <TextField
            name="password"
            label="Пароль"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel control={<Checkbox name="remember" />} label="Запам'ятати мене" />

          <Link variant="subtitle2" underline="hover">
            Забули пароль?
          </Link>
        </Stack>

        <Collapse in={open}>
          <Alert
            sx={{ my: 3 }}
            severity="error"
            onClose={() => {
              setOpen(false);
            }}
          >
            {errorText}
          </Alert>
        </Collapse>

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          {isLoading ? `Вхід` : <CircularProgress color={'inherit'} size={'1.5rem'} />}
        </LoadingButton>
      </Box>
    </>
  );
}
