import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Container, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';
import { useAuth } from '../hooks/useAuth';
import { URL_GET_LANGSETS_ADD } from '../config';
import { CheckSpaces } from '../utils/utilsFunction';

export default function LangSetsAdd() {
  const { user } = useAuth();
  const [snackBar, setSnackBar] = useState({ show: false, message: '', alert: 'success' });
  const [isValidTranslit, setIsValidTranslit] = useState({ isValid: true, errorText: '' });
  const [loading, setLoading] = useState(false);
  const timer = useRef();
  const [translit, setTranslit] = useState('');
  const [ua, setUa] = useState('');
  const [pl, setPl] = useState('');
  const [en, setEn] = useState('');
  const [ru, setRu] = useState('');

  // Щоб зробити крок назад
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  // Відправка форми
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!CheckSpaces(translit) || translit === '') {
      setIsValidTranslit({ isValid: false, errorText: "Tранслітерація обов'язкове поле!" });
      return;
      // eslint-disable-next-line no-else-return
    } else {
      setIsValidTranslit({ isValid: true, errorText: '' });
    }

    setLoading(true);

    const data = {
      id: user.dataUser.id,
      translit,
      ua,
      pl,
      en,
      ru,
    };

    fetch(URL_GET_LANGSETS_ADD, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);

        if (json.ok === 1 && json.status === 201) {
          setSnackBar({ show: true, message: 'Дані успішно збережені!', alert: 'success' });
        } else {
          setSnackBar({ show: true, message: 'Помилка збереження!', alert: 'error' });
        }

        if (!loading) {
          timer.current = setTimeout(() => {
            setLoading(false);
            if (json.ok === 1 && json.status === 201) {
              // Перейти на сторінку слів
              navigate('..', { relative: 'path' });
            }
          }, 1000);
        }
      })
      .catch((error) => {
        // Помилка
        console.error(error);
        setSnackBar({ show: true, message: error.message, alert: 'error' });
        // Закрити прогрес збереження
        if (!loading) {
          timer.current = setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
  };

  // Закрити вікно сповіщення
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar({ show: false, message: '', alert: 'success' });
  };

  return (
    <>
      <Box component="form" id="formEditUser" noValidate autoComplete="off">
        <Helmet>
          <title> Додати мовний набір </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Додати мовний набір
            </Typography>
          </Stack>

          <Stack>
            <Box
              sx={{
                '& > :not(style)': { ml: 1, mb: 1 },
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
              }}
            >
              <Button
                variant="contained"
                startIcon={
                  loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Iconify icon="eva:save-outline" />
                }
                onClick={handleSubmit}
              >
                Зберегти
              </Button>

              <Button onClick={goBack} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Відмінити
              </Button>
            </Box>
          </Stack>

          <Box
            sx={{
              '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
            }}
          >
            <TextField
              required
              label="Tранслітерація"
              variant="outlined"
              value={translit}
              onChange={(e) => setTranslit(e.target.value)}
              error={!isValidTranslit.isValid}
              helperText={!isValidTranslit.isValid ? isValidTranslit.errorText : ''}
            />
          </Box>
          <Box
            sx={{
              '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '50%', md: '50%' } },
            }}
          >
            <TextField
              multiline
              maxRows={4}
              label="UA"
              variant="outlined"
              value={ua}
              onChange={(e) => setUa(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="PL"
              variant="outlined"
              value={pl}
              onChange={(e) => setPl(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="EN"
              variant="outlined"
              value={en}
              onChange={(e) => setEn(e.target.value)}
            />
            <TextField
              multiline
              maxRows={4}
              label="RU"
              variant="outlined"
              value={ru}
              onChange={(e) => setRu(e.target.value)}
            />
          </Box>
        </Container>
        <Snackbar
          open={snackBar.show}
          autoHideDuration={3000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackBar} severity={snackBar.alert} variant="filled" sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
