import { MuiFileInput } from 'mui-file-input';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Stack,
  Button,
  Typography,
  TextField,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';

export default function UserAdd() {
  // Інтпут типу додати файл
  const [file, setFile] = useState(null);

  const handleChange = (newFile) => {
    setFile(newFile);
    // alert(newFile.name);
  };

  // Щоб зробити крок назад
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const form = document.querySelector('form');

  // Відправка
  const handleClick = (event) => {
    event.preventDefault();

    const formData = new FormData(form);
    const API_URL = 'https://admin.vyhivskyi.com/auth-api/register.php';

    fetch(API_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      });
  };

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleClick}>
      <Helmet>
        <title> Додаємо користувача </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Додаємо користувача
          </Typography>
        </Stack>

        <Stack>
          <Box
            sx={{
              '& > :not(style)': { ml: 1, mb: 1 },
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
              Зберегти
            </Button>
            <Button onClick={goBack} variant="outlined" startIcon={<Iconify icon="eva:arrow-back-fill" />}>
              Відмінити
            </Button>
          </Box>
        </Stack>

        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField id="outlined-basic" name="surname" label="Прізвище" variant="outlined" />
          <TextField id="outlined-basic" name="name" label="Ім'я" variant="outlined" />
          <TextField id="outlined-basic" name="fatherName" label="По батькові" variant="outlined" />
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField id="outlined-basic" name="phohe" label="Номер телефону" variant="outlined" />
          <TextField id="outlined-basic" name="email" label="Ел. пошта" variant="outlined" />
        </Box>

        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <TextField id="outlined-basic" name="company" label="Компанія" variant="outlined" />
          <TextField id="outlined-basic" name="profession" label="Професія" variant="outlined" />
        </Box>

        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked name="confirmed" />} label="Підтверджено" />
          </FormGroup>
        </Box>
        <Box
          sx={{
            '& > :not(style)': { mb: 2, mr: 2, width: { xs: '100%', sm: '25ch', md: '32ch' } },
          }}
        >
          <MuiFileInput
            label="Вибрати фото"
            variant="outlined"
            value={file}
            onChange={handleChange}
            getInputText={(value) => (value ? 'Файл вираний' : '')}
          />
        </Box>
      </Container>
    </Box>
  );
}
