import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import UserAdd from './pages/UserAdd';
import UserEdit from './pages/UserEdit';
import Profile from './pages/Profile';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { RequireAuth } from './hoc/RequireAuth';
import Settings from './pages/Settings';
import LangSets from './pages/LangSets';
import LangSetsEdit from './pages/LangSetsEdit';
import LangSetsAdd from './pages/LangSetsAdd';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: (
            <RequireAuth>
              <DashboardAppPage />
            </RequireAuth>
          ),
        },
        {
          path: 'user',
          element: (
            <RequireAuth>
              <UserPage />
            </RequireAuth>
          ),
        },
        {
          path: 'user/add',
          element: (
            <RequireAuth>
              <UserAdd />
            </RequireAuth>
          ),
        },
        {
          path: 'user/edit',
          element: (
            <RequireAuth>
              <UserEdit />
            </RequireAuth>
          ),
        },
        {
          path: 'products',
          element: (
            <RequireAuth>
              <ProductsPage />
            </RequireAuth>
          ),
        },
        {
          path: 'lang-sets',
          element: (
            <RequireAuth>
              <LangSets />
            </RequireAuth>
          ),
        },
        {
          path: 'lang-sets/add',
          element: (
            <RequireAuth>
              <LangSetsAdd />
            </RequireAuth>
          ),
        },
        {
          path: 'lang-sets/edit/:id',
          element: (
            <RequireAuth>
              <LangSetsEdit />
            </RequireAuth>
          ),
        },
        {
          path: 'blog',
          element: (
            <RequireAuth>
              <BlogPage />
            </RequireAuth>
          ),
        },
        {
          path: 'profile',
          element: (
            <RequireAuth>
              <Profile />
            </RequireAuth>
          ),
        },
        {
          path: 'settings',
          element: (
            <RequireAuth>
              <Settings />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
